import { useI18next } from 'gatsby-plugin-react-i18next'

const ChangeLanguage = () => {
  const { language, changeLanguage } = useI18next()

  const targetLang = language === 'pl' ? 'en' : 'pl'

  return (
    <button
      style={{
        background: 'none',
        cursor: 'pointer',
        border: 'none',
        color: 'var(--theme-ui-colors-omegaDarker, #2d3748)',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1rem',
        fontWeight: 600,
        marginLeft: 20
      }}
      onClick={e => changeLanguage(targetLang)}
    >
      {targetLang.toUpperCase()}
    </button>
  )
}

export default ChangeLanguage
