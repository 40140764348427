import { translateBlockContentNode } from './translateBlockContentNode'

export const normalizeBlockContentNodes = (
  allContent,
  t,
  key = 'identifier'
) => {
  return allContent?.reduce((normalized, block) => {
    normalized[block[key]] = t ? translateBlockContentNode(block, t) : block

    return normalized
  }, {})
}
