export const translateBlockContentNode = (block, t) => {
  block.text?.forEach(tobj => {
    tobj.text = t(tobj.text)
  })
  block.buttons?.forEach(bobj => {
    bobj.text = t(bobj.text)
  })
  block.collection?.forEach(cobj => {
    cobj.text?.forEach(tobj => {
      if (tobj) {
        tobj.text = t(tobj?.text)
      }
    })
    cobj.buttons?.forEach(bobj => {
      if (bobj) {
        bobj.text = t(bobj?.text)
      }
    })
  })

  return block
}
